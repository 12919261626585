<template>
  <div v-if="alternative" class="cartitemlayout__subs cartitem__subs leftright">
    <span v-html="message"></span>
    <a
      href="/"
      v-bind:id="itemid"
      class="alt_popup">
      View
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EasyAlternatives',
  props: {
    item: {
      type: Object,
      required: false,
    }
  },
  components: {
  },
  computed: {
    ...mapGetters({
    }),
    alternative() {
      return this.item.suggestion;
    },
    message() {
      const { reason } = this.item.suggestion;
      let text;
      const amount = this.item.suggestion.saving && window.nxDatalayer.global.displayprices
        ? this.item.suggestion.saving
        : '';
      const qty = `${this.item.suggestion.atoms} ${this.item.suggestion.measure}`;
      const fast_reason = 'Recommended faster delivery product alternative';
      switch (reason) {
        case 'Z101':
          text = `Save <b>${amount}</b> with a better value alternative`;
          break;
        case 'Z102':
          text = `Save <b>${amount}</b> with a better value alternative`;
          break;
        case 'Z103':
          text = 'Recommended eco-conscious product alternative';
          break;
        case 'Z104':
          text = `Save <b>${amount} per ${qty}</b> with a better value alternative`;
          break;
        case 'Z105':
          text = `Save <b>${amount} per ${qty}</b> with a better value alternative`;
          break;
        case 'B001':
          text = fast_reason;
          break;
        case 'Z001':
          text = fast_reason;
          break;
        case 'Z002':
          text = fast_reason;
          break;
        default:
          text = 'Recommended alternative';
      }
      return text;
    },
    itemid() {
      return this.item.itemid;
    },
  },
  mounted() {
    if (this.alternative) {
      this.$nextTick(() => window.nx?.orders?.dialogs());
    }
  },
};
</script>
